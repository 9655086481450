<template>
  <div id="bg">
    <el-form ref="form" label-width="100px" size="small">
        <el-form-item label="谷歌验证器">
          <el-button type="primary" @click="showBindOtp()" size="medium">{{adminInfo.is_otp?"解除绑定":"未绑定"}}</el-button>
        </el-form-item>
    </el-form>

    <el-dialog title="绑定谷歌身份验证器" v-model="dialogBindGuge" width="400px" top="10vh" :close-on-click-modal="false" >
      <div style="display: flex;flex-direction: column; justify-content: center">
        <qrcode-vue :value="form.qrcode" :size="300" level="H" />
        <el-form ref="form" label-width="100px" size="small">
          <el-form-item label="验证码">
            <el-input v-model="form.code" placeholder="请输入谷歌验证器验证码"></el-input>
          </el-form-item>
        </el-form>
        <el-button type="primary" @click="verifyOtp()">绑定</el-button>
      </div>

    </el-dialog>
<!--    解除绑定-->
    <el-dialog title="解除绑定" v-model="dialogUnbind" width="400px" top="10vh" :close-on-click-modal="false" >
      <div style="display: flex;flex-direction: column; justify-content: center">
      <el-form ref="form" label-width="100px" size="small">
        <el-form-item label="验证码">
          <el-input v-model="unbindform.code" placeholder="请输入谷歌验证器验证码"></el-input>
        </el-form-item>
      </el-form>
      <el-button type="primary" @click="unbindOtp()">解除绑定</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import httpClient from "@/config/httpClient";
import QrcodeVue from 'qrcode.vue'

export default {
  name: "admin_center",
  data() {
    return {
      qrcode:"",
      dialogBindGuge:false,
      form:{
        code:"",
        secret:"",
        qrcode:""
      },
      unbindform:{
        code:"",
      },
      dialogUnbind:false,
      adminInfo:{
        nickname:"",
        is_otp:false,
      }
    };
  },
  components: {
    QrcodeVue,
  },
  mounted() {
    this.getAdminInfo()
  },
  methods: {
    getAdminInfo(){
      httpClient("getAdminInfo").post().then((res)=>{
        if (res.code != 0){
          this.$message({message:res.msg,type:"error"})
        }else{
          this.adminInfo = res.data
        }
      })
    },
    unbindOtp(){
      // 读取二维码
      httpClient("unbindOtp").post(this.unbindform).then((res)=>{
        if(res.code != 0){
          this.$message({message:res.msg,type:"error"})
        }else{
          this.dialogUnbind = false
          this.$message({message:"解绑成功",type:"success"})
          this.getAdminInfo()
        }
      })
    },
    showBindOtp(){
      if (this.adminInfo.is_otp){
        this.dialogUnbind = true
        return
      }
      // 读取二维码
      httpClient("getOtpQrcode").post().then((res)=>{
        if(res.code != 0){
          this.$message({message:res.msg,type:"error"})
        }else{
          this.dialogBindGuge = true
          this.form.secret = res.data.secret
          this.form.qrcode = res.data.qrcode
        }
      })
    },
    verifyOtp(){
      httpClient("verifyOtp").post(this.form).then((res)=>{
        if(res.code != 0){
          this.$message({message:res.msg,type:"error"})
        }else{
          this.dialogBindGuge = false
          this.$message({message:"绑定成功",type:"success"})
          this.getAdminInfo()
        }
      })
    }
  },
};
</script>


<style scoped>
</style>
